// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-group{
position: relative;
margin-bottom: 2rem;
width: 100%;
}


.form-group label{
  font-size: 1.3rem;
}

.form-group input{
  height: 3rem;
  font-size: 1.7rem;
  width: 100% !important;
 
}

.form-group img {
  display: block;
  position: absolute;
  z-index: 1;
  width: 2.5rem;
  right: 2rem;
  top : 2.3rem;
}
.modal-title{
  text-align: center;
}

@media (max-width: 991px) {
 .form-group #password-input-img{
  top : 4.2rem;

 }
}

.form-group .error-message{
  color: red !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/ResetPassword/style.css"],"names":[],"mappings":"AAAA;AACA,kBAAkB;AAClB,mBAAmB;AACnB,WAAW;AACX;;;AAGA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,sBAAsB;;AAExB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,UAAU;EACV,aAAa;EACb,WAAW;EACX,YAAY;AACd;AACA;EACE,kBAAkB;AACpB;;AAEA;CACC;EACC,YAAY;;CAEb;AACD;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".form-group{\nposition: relative;\nmargin-bottom: 2rem;\nwidth: 100%;\n}\n\n\n.form-group label{\n  font-size: 1.3rem;\n}\n\n.form-group input{\n  height: 3rem;\n  font-size: 1.7rem;\n  width: 100% !important;\n \n}\n\n.form-group img {\n  display: block;\n  position: absolute;\n  z-index: 1;\n  width: 2.5rem;\n  right: 2rem;\n  top : 2.3rem;\n}\n.modal-title{\n  text-align: center;\n}\n\n@media (max-width: 991px) {\n .form-group #password-input-img{\n  top : 4.2rem;\n\n }\n}\n\n.form-group .error-message{\n  color: red !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
