
export async function getRanking() {
  try {
    const response = await fetch("https://www.millionnaire.andreadellabiancia.fr/api/ranking") 
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
     const  data = await response.json()
     return data;
  } catch (error) {
    console.log(error)
  }
}
