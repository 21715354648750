// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timerProgressBar{
  height: 1rem;
  background-color: rgb(25, 0, 255);
}

.container-alert{
  width: 1000px;
}

.title-alert{
  font-size: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/HelpContainer/helpAlert.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iCAAiC;AACnC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".timerProgressBar{\n  height: 1rem;\n  background-color: rgb(25, 0, 255);\n}\n\n.container-alert{\n  width: 1000px;\n}\n\n.title-alert{\n  font-size: 1.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
