// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#error-page {
 background-color: white;
 position: absolute;
 width: 100%;
 height: 100%;
 top: 5rem;
 z-index: -10;
}

#error-page h1{
 margin-top: 5rem; 
 text-align: center;
}

#error-page p{
  margin-top: 2rem; 
  font-size: 1.7rem;
  text-align: center;
 }

#error-page div {
  width: 100%;
display: flex;
justify-content: center;
}

#error-page img{
 width: 100%;
}

@media (min-width: 600px) {
  #error-page img{
    width: 60%;
   }
}

@media (min-width: 1000px) {
  #error-page img{
    width: 30%;
   }
}


.error-page {
  
}`, "",{"version":3,"sources":["webpack://./src/pages/Error/style.css"],"names":[],"mappings":"AAAA;CACC,uBAAuB;CACvB,kBAAkB;CAClB,WAAW;CACX,YAAY;CACZ,SAAS;CACT,YAAY;AACb;;AAEA;CACC,gBAAgB;CAChB,kBAAkB;AACnB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;CACnB;;AAED;EACE,WAAW;AACb,aAAa;AACb,uBAAuB;AACvB;;AAEA;CACC,WAAW;AACZ;;AAEA;EACE;IACE,UAAU;GACX;AACH;;AAEA;EACE;IACE,UAAU;GACX;AACH;;;AAGA;;AAEA","sourcesContent":["#error-page {\n background-color: white;\n position: absolute;\n width: 100%;\n height: 100%;\n top: 5rem;\n z-index: -10;\n}\n\n#error-page h1{\n margin-top: 5rem; \n text-align: center;\n}\n\n#error-page p{\n  margin-top: 2rem; \n  font-size: 1.7rem;\n  text-align: center;\n }\n\n#error-page div {\n  width: 100%;\ndisplay: flex;\njustify-content: center;\n}\n\n#error-page img{\n width: 100%;\n}\n\n@media (min-width: 600px) {\n  #error-page img{\n    width: 60%;\n   }\n}\n\n@media (min-width: 1000px) {\n  #error-page img{\n    width: 30%;\n   }\n}\n\n\n.error-page {\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
